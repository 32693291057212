import React, { useEffect, useState } from "react"
import { Faction, FactionDataService } from "../services/FactionDataService"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./factionInfo.module.scss"
import MainLayout from "../layouts/MainLayout"
import parse from "html-react-parser"
import { getQueryParams } from "../helpers/getQueryParams"
import SEO from "../components/SEO"

export default function FactionInfo({ data }) {
  const [factionIsSetByUrlParam, setFactionIsSetByUrlParam] = useState(false)
  const factionDataService = new FactionDataService()
  const [selectedFaction, setSelectedFaction] = useState(
    factionDataService.get(Faction.ECO)
  )

  useEffect(() => {
    const params = getQueryParams(window.location.href)
    if (Object.keys(params).length > 0 && !factionIsSetByUrlParam) {
      setSelectedFaction(factionDataService.get(params.a))
      setFactionIsSetByUrlParam(true)
    }
    window.history.pushState(
      "page2",
      "Title",
      "/factions?a=" + selectedFaction.faction
    )
  }, [selectedFaction])

  const descriptionToTwoColumns = description => {
    var parafArray = description.split("<br/><br/>")

    var first = parafArray.slice(0, parafArray.length / 2)
    var second = parafArray.slice(parafArray.length / 2, parafArray.length)

    return [first.join("<br/><br/>"), second.join("<br/><br/>")]
  }

  const getActiveTabStyleClass = faction => {
    switch (faction) {
      case "Eco":
        return styles.factionInfoTabsEcoActive
      case "Tech":
        return styles.factionInfoTabsTechActive
      case "Industrial":
        return styles.factionInfoTabsIndustrialActive
      case "Traditional":
        return styles.factionInfoTabsTraditionalActive

      default:
        break
    }
  }

  const getFactionFadedBackroundStyle = faction => {
    return {
      background: `url('${
        data[faction.toLowerCase() + "Faded"].childImageSharp.gatsbyImageData
          .images.fallback.src
      }')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "spread",
      backgroundPosition: "center",
    }
  }

  const getFactionLitepaperImage = faction => {
    switch (faction) {
      case Faction.ECO:
        throw new Error("No Eco litepaper yet")
      case Faction.TECH:
        return (
          <GatsbyImage
            image={getImage(data.techFactionLitePaperBanner)}
            alt={`Tech faction litepaper banner`}
          />
        )
      case Faction.INDUSTRIAL:
        return (
          <GatsbyImage
            image={getImage(data.industrialFactionLitePaperBanner)}
            alt={`Industrial faction litepaper banner`}
          />
        )
      case Faction.TRADITIONAL:
        return (
          <GatsbyImage
            image={getImage(data.traditionalFactionLitePaperBanner)}
            alt={`Traditional faction litepaper banner`}
          />
        )
      default:
        break
    }
  }

  return (
    <MainLayout>
      <div className={styles.factionInfoPage}>
        <div className={`${styles.factionInfoTabs}`}>
          <div className="container">
            <div className="row">
              {factionDataService.getAll().map(f => {
                return (
                  <div className="col col-lg-3">
                    <div
                      className={`${styles.factionInfoTabsItem} ${
                        selectedFaction.name === f.name
                          ? getActiveTabStyleClass(f.name)
                          : ""
                      }`}
                      onClick={() =>
                        setSelectedFaction(factionDataService.get(f.name))
                      }
                    >
                      {f.name}
                      <GatsbyImage
                        className={`${styles.factionInfoTabsItemImg}`}
                        style={{
                          filter:
                            selectedFaction.name === f.name
                              ? "none"
                              : "grayscale(100%)",
                        }}
                        image={getImage(data[f.name.toLowerCase() + "Badge"])}
                        alt={`Eco faction badge`}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {selectedFaction ? (
          <div className={styles.factionInfoContent}>
            <div className="container">
              <div className="row">
                <div className="col col-lg-12 flex-center-hor">
                  <GatsbyImage
                    className={``}
                    image={getImage(
                      data[selectedFaction.name.toLowerCase() + "Header"]
                    )}
                    alt={`${selectedFaction} faction header`}
                  />
                </div>
                <div className="col-lg-12">
                  <div className={styles.factionInfoHeader}>
                    <h2 style={{ color: selectedFaction.colorCode }}>
                      {selectedFaction.name} Faction
                    </h2>
                    <ul className={styles.socials}>
                      <li>
                        <a target="_blank" href={selectedFaction.facebookURL}>
                          <GatsbyImage
                            image={getImage(data.fb)}
                            alt={`Facebook social icon`}
                          />
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={selectedFaction.twitterURL}>
                          <GatsbyImage
                            image={getImage(data.twitter)}
                            alt={`Twitter social icon`}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={`row ${styles.factionInfoContentDesc}`}>
                <div className="col col-12 col-sm-12 col-lg-6">
                  {parse(
                    descriptionToTwoColumns(selectedFaction.description)[0]
                  )}
                </div>
                <div className="col col-12 col-sm-12 col-lg-6">
                  {parse(
                    descriptionToTwoColumns(selectedFaction.description)[1]
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  className={`col col-lg-12 ${styles.factionInfoContentStats}`}
                >
                  <GatsbyImage
                    className={``}
                    image={getImage(
                      data[selectedFaction.name.toLowerCase() + "Stats"]
                    )}
                    alt={`${selectedFaction} faction stats`}
                  />
                </div>
              </div>
            </div>
            <div
              className={styles.factionInfoContentAdvisorBio}
              style={getFactionFadedBackroundStyle(selectedFaction.name)}
              // style="@GetFactionFadedBackgroundStyle(SelectedFaction)"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 style={{ color: selectedFaction.colorCode }}>
                      {selectedFaction.advisor.fullName} & the{" "}
                      {selectedFaction.nameAlias}
                    </h2>
                  </div>
                </div>
                <div
                  className={`row ${styles.factionInfoContentAdvisorBioText}`}
                >
                  <div className="col col-12 col-sm-12 col-lg-6">
                    {parse(
                      descriptionToTwoColumns(
                        selectedFaction.advisor.backgroundStory
                      )[0]
                    )}
                  </div>
                  <div className="col col-12 col-sm-12 col-lg-6">
                    {parse(
                      descriptionToTwoColumns(
                        selectedFaction.advisor.backgroundStory
                      )[1]
                    )}
                  </div>
                </div>
                <img
                  src="/images/gradients/gradient_1F2032_top.png"
                  className={`${styles.factionInfoContentAdvisorBioGradientTop}`}
                  alt="Gradient img"
                />
                <img
                  src="/images/gradients/gradient_1F2032_bottom.png"
                  className={`${styles.factionInfoContentAdvisorBioGradientBottom}`}
                  alt="Gradient img"
                />
              </div>
            </div>
            {selectedFaction.litepaperUrl ? (
              <div className="container">
                <div className="row justify-center">
                  <div className={styles.litepaperBanner}>
                    <a href={`/litepaper?type=${selectedFaction.faction}`}>
                      {getFactionLitepaperImage(selectedFaction.faction)}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </MainLayout>
  )
}

export const bgImageQuery = graphql`
  fragment bgImageQuery on File {
    childImageSharp {
      gatsbyImageData(blurredOptions: { width: 10 }, placeholder: BLURRED)
    }
  }
`

export const query = graphql`
  query {
    techFactionLitePaperBanner: file(
      relativePath: { eq: "factions/banners/Tech_litepaper_banner.png" }
    ) {
      ...defaultImageQuery
    }

    industrialFactionLitePaperBanner: file(
      relativePath: { eq: "factions/banners/Industrial_litepaper_banner.png" }
    ) {
      ...defaultImageQuery
    }

    traditionalFactionLitePaperBanner: file(
      relativePath: { eq: "factions/banners/Traditional_litepaper_banner.png" }
    ) {
      ...defaultImageQuery
    }

    ecoBadge: file(relativePath: { eq: "factions/badges/Eco_badge.png" }) {
      ...defaultImageQuery
    }

    techBadge: file(relativePath: { eq: "factions/badges/Tech_badge.png" }) {
      ...defaultImageQuery
    }

    industrialBadge: file(
      relativePath: { eq: "factions/badges/Industrial_badge.png" }
    ) {
      ...defaultImageQuery
    }

    traditionalBadge: file(
      relativePath: { eq: "factions/badges/Traditional_badge.png" }
    ) {
      ...defaultImageQuery
    }

    ecoHeader: file(relativePath: { eq: "factions/headers/eco_header.png" }) {
      ...defaultImageQuery
    }

    techHeader: file(relativePath: { eq: "factions/headers/tech_header.png" }) {
      ...defaultImageQuery
    }

    industrialHeader: file(
      relativePath: { eq: "factions/headers/industrial_header.png" }
    ) {
      ...defaultImageQuery
    }

    traditionalHeader: file(
      relativePath: { eq: "factions/headers/traditional_header.png" }
    ) {
      ...defaultImageQuery
    }

    ecoFaded: file(
      relativePath: { eq: "factions/faded_backgrounds/bg_eco_faded.png" }
    ) {
      ...defaultImageQuery
    }

    techFaded: file(
      relativePath: { eq: "factions/faded_backgrounds/bg_tech_faded.png" }
    ) {
      ...defaultImageQuery
    }

    industrialFaded: file(
      relativePath: { eq: "factions/faded_backgrounds/bg_industrial_faded.png" }
    ) {
      ...defaultImageQuery
    }

    traditionalFaded: file(
      relativePath: {
        eq: "factions/faded_backgrounds/bg_traditional_faded.png"
      }
    ) {
      ...defaultImageQuery
    }

    ecoStats: file(relativePath: { eq: "factions/stats/eco_stats.png" }) {
      ...defaultImageQuery
    }

    techStats: file(relativePath: { eq: "factions/stats/tech_stats.png" }) {
      ...defaultImageQuery
    }

    industrialStats: file(
      relativePath: { eq: "factions/stats/industrial_stats.png" }
    ) {
      ...defaultImageQuery
    }

    traditionalStats: file(
      relativePath: { eq: "factions/stats/traditional_stats.png" }
    ) {
      ...defaultImageQuery
    }

    gradientTop: file(
      relativePath: { eq: "gradients/gradient_1F2032_top.png" }
    ) {
      ...defaultImageQuery
    }

    gradientBottom: file(
      relativePath: { eq: "gradients/gradient_1F2032_bottom.png" }
    ) {
      ...defaultImageQuery
    }

    fb: file(
      relativePath: { eq: "icons/social/gray/icon_social_fb_gray.png" }
    ) {
      ...defaultImageQuery
    }

    instagram: file(
      relativePath: { eq: "icons/social/gray/icon_social_instagram_gray.png" }
    ) {
      ...defaultImageQuery
    }

    linkedin: file(
      relativePath: { eq: "icons/social/gray/icon_social_linkedin_gray.png" }
    ) {
      ...defaultImageQuery
    }

    twitter: file(
      relativePath: { eq: "icons/social/gray/icon_social_twitter_gray.png" }
    ) {
      ...defaultImageQuery
    }
  }
`
export const Head = () => {
  const ecoFactionArticle = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://cryptopia.com/factions?a=eco",
    },
    headline: "Eco Faction",
    description:
      "The future is nothing without the Earth, and this is what Earth’s Disciples will wholeheartedly protect. The new land brings new ways to cultivate Earth to its finest form but also makes it vulnerable to being squeezed to its last drop. The Earth’s Disciples have launched various projects, demonstrations, and political protests to make sure that these lands are sustainable with their local flora and fauna. They also make sure to create safeguards that prevent the environment from further harm due to local waste and toxic by-products.",
    image:
      "https://cryptopia.com/images/factions/factionContainers/eco_select_container.png",
    author: {
      "@type": "Person",
      name: "Mark Aragona",
    },
    publisher: {
      "@type": "Organization",
      name: "Cryptopia Inc.",
      logo: {
        "@type": "ImageObject",
        url: "https://cryptopia.com/icon-60.png",
      },
    },
    datePublished: "2022-01-01",
  }

  const techFactionArticle = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://cryptopia.com/factions?a=tech",
    },
    headline: "Tech Faction",
    description:
      "Ambitious and innovative, the Technocrats have established themselves as one of the main pillars of Cryptopia society. Everything and anything you use is most likely created by the Technocrats: from your phones, screens, buildings, and even your grandmother’s pacemaker. Though dreams like these don’t come easy, and most especially, cheap, the Technocrats are confident in their capability to turn their ideas into reality.",
    image:
      "https://cryptopia.com/images/factions/factionContainers/tech_select_container.png",
    author: {
      "@type": "Person",
      name: "Mark Aragona",
    },
    publisher: {
      "@type": "Organization",
      name: "Cryptopia Inc.",
      logo: {
        "@type": "ImageObject",
        url: "https://cryptopia.com/icon-60.png",
      },
    },
    datePublished: "2022-01-01",
  }

  const industrialFactionArticle = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://cryptopia.com/factions?a=industrial",
    },
    headline: "Industrial Faction",
    description:
      "Cryptopia is a luxury that few can afford, and many can’t fully grasp. Cryptopia may just be distant islands to the untrained eye; to the Inheritors, it’s the new frontier. With many countries having their customs, laws, and traditions, Cryptopia offers a clean slate for all. Making Cryptopia the perfect place to establish foundations for businesses that are way too big and ahead of their time for any country and their limited laws and customs. The Inheritors have sought the land as prime real estate. It is for brave pioneers to establish their business, for those with influence to innovate, and for those who are willing to take the risk, front row seats to the future.",
    image:
      "https://cryptopia.com/images/factions/factionContainers/industrial_select_container.png",
    author: {
      "@type": "Person",
      name: "Mark Aragona",
    },
    publisher: {
      "@type": "Organization",
      name: "Cryptopia Inc.",
      logo: {
        "@type": "ImageObject",
        url: "https://cryptopia.com/icon-60.png",
      },
    },
    datePublished: "2022-01-01",
  }

  const traditionalFactionArticle = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://cryptopia.com/factions?a=traditional",
    },
    headline: "Traditional Faction",
    description:
      "The Unifiers are the representatives of the countless people who arrived with only their belongings and a dream. Declaring themselves as the people’s voice, the Unifiers are usually the spokesperson for the concerns and well-being of workers, customers, and citizens. They often are the ones crafting the laws and regulations that keep businesses and people in power in line.",
    image:
      "https://cryptopia.com/images/factions/factionContainers/traditional_select_container.png",
    author: {
      "@type": "Person",
      name: "Mark Aragona",
    },
    publisher: {
      "@type": "Organization",
      name: "Cryptopia Inc.",
      logo: {
        "@type": "ImageObject",
        url: "https://cryptopia.com/icon-60.png",
      },
    },
    datePublished: "2022-01-01",
  }

  const schemaMarkup = [
    ecoFactionArticle,
    techFactionArticle,
    industrialFactionArticle,
    traditionalFactionArticle,
  ]

  return <SEO schemaMarkup={schemaMarkup} />
}
